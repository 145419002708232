import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import { concat, debounce, delay, filter, find, map } from 'lodash-es';
import request from 'superagent';
import AutocompleteResult from './AutocompleteResult';
import AutocompleteFieldResults from './AutocompleteFieldResults';
import LoadingIcon from '../SearchLoadingIcon';

class AutocompleteField extends React.Component {
  static propTypes = {
    initiallySelected: PropTypes.array,
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    searchHandler: PropTypes.func,
    onSearch: PropTypes.func,
    onSelectResult: PropTypes.func,
    onChangeItems: PropTypes.func,
    placeholder: PropTypes.string,
    initialValue: PropTypes.string,
    delayShortQuery: PropTypes.bool,
    analyticsEventPrefix: PropTypes.string,
    analyticsFieldName: PropTypes.string
  };

  static defaultProps = {
    delayShortQuery: false
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.makeFocused = this.makeFocused.bind(this);

    this.state = {
      displayValue: '',
      selected: map(this.props.initiallySelected, this.modelizeResult),
      loading: false,
      showingResults: false,
      results: [],
      selectedResultIndex: null,
      focused: false
    };

    if (this.props.initialValue) {
      this.state.showingResults = true;
      this.state.displayValue = this.props.initialValue;
      this.doSearch(this.props.initialValue);
    }

    this.fieldSelected = false;
    this.isBeingClicked = false;
  }

  handleChange(event) {
    this.setState({ displayValue: event.target.value });
  }

  get trackingEnabled() {
    return !!(this.props.analyticsEventPrefix && this.props.analyticsFieldName);
  }

  trackEvent(eventName, value = null) {
    if (!this.trackingEnabled) {
      return;
    }

    Analytics.trackEvent(`${this.props.analyticsEventPrefix} ${eventName}`, {
      field_name: this.props.analyticsFieldName,
      ...(value && { value: value })
    });
  }

  render() {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- [TODO]
      <div
        className={`AutocompleteField ${this.focusedClass}`}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
        data-testid="autocomplete-field"
      >
        <LoadingIcon loading={this.state.loading} />
        <div className="AutocompleteField-item-list">
          {this.renderPlaceholder}
          {this.renderSelectedItems}
          <input
            id={this.props.id}
            type="text"
            className="AutocompleteField-text-input"
            ref={this.storeInputField}
            onInput={this.didUpdateQuery}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onFocus={this.makeFocused}
            onKeyDown={this.handleNavigationKeys}
            value={this.state.displayValue}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            aria-label={this.props.placeholder}
          />
        </div>
        <button
          type="button"
          className={`AutocompleteField-reset-button ${this.state.selected.length === 0 ? 'hidden' : null}`}
          onClick={this.clearAll}
        >
          {I18n.t('clearable.clear')}
        </button>
        {this.renderResults}
      </div>
    );
  }

  get renderSelectedItems() {
    return this.state.selected.map((item) => {
      return (
        <span className="AutocompleteField-item-list-item" key={item.id}>
          <button
            type="button"
            className="AutocompleteField-item-list-item-remove-button"
            onClick={() => this.didRemoveItem(item)}
          >
            {I18n.t('removable.remove')}
          </button>
          <span className="AutocompleteField-item-list-item-title">{item.description}</span>
        </span>
      );
    });
  }

  get renderResults() {
    if (!this.state.showingResults) {
      return;
    }
    if (this.state.results.length === 0) {
      return <AutocompleteFieldEmptyMessage queryText={this.state.displayValue} />;
    }

    return (
      <AutocompleteFieldResults
        results={this.state.results}
        type={this.props.type}
        onSelectResult={this.selectResult}
        currentlySelected={this.state.results[this.state.selectedResultIndex]}
        ref={(ref) => (this.resultComponent = ref)}
      />
    );
  }

  get renderPlaceholder() {
    if (this.state.focused) {
      return;
    }
    if (this.state.displayValue !== '' || this.state.selected.length !== 0) {
      return;
    }

    return <div className="AutocompleteField-placeholder">{this.props.placeholder}</div>;
  }

  // Helpers
  //#############################################################################

  get focusedClass() {
    return this.state.focused ? 'focused' : 'blurred';
  }

  searchHandler(type, text) {
    if (this.props.searchHandler) {
      return this.props.searchHandler(type, text);
    } else {
      let path = `/explorer/autocomplete/${type}?q=${text}`;

      return request
        .get(path)
        .set('X-Requested-With', 'XMLHttpRequest')
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json');
    }
  }

  // Keyboard handers and navigation
  //#############################################################################

  handleNavigationKeys = (e) => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        this.clearSearch();

        break;
      case 'Enter':
        if (this.state.showingResults) {
          e.preventDefault();
        }

        if (this.state.selectedResultIndex != null && this.state.results[this.state.selectedResultIndex]) {
          // disable Advance Search submitting
          e.stopPropagation();

          this.selectResult(this.state.results[this.state.selectedResultIndex]);
        }

        break;
      case 'ArrowUp':
        e.preventDefault();
        this.moveSelectionUp();

        break;
      case 'ArrowDown':
        e.preventDefault();
        this.moveSelectionDown();

        break;
      case 'Backspace':
        if (this.state.displayValue === '') {
          this.removeLastSelection();
        }

        break;
    }
  };

  moveSelectionUp() {
    let next = this.state.results.length - 1;

    if (this.state.selectedResultIndex !== 0) {
      next = this.state.selectedResultIndex - 1;
    }

    this.setState({ selectedResultIndex: next });
  }

  moveSelectionDown() {
    let next = 0;

    if (this.state.selectedResultIndex !== this.state.results.length - 1) {
      next = this.state.selectedResultIndex + 1;
    }

    this.setState({ selectedResultIndex: next });
  }

  // Search event handlers
  //#############################################################################

  didUpdateQuery = (e) => {
    const text = e.target.value;

    if (text.trim() === '') {
      this.clearSearch();
      return;
    }

    this.setState(
      {
        displayValue: text,
        showingResults: this.state.results.length !== 0
      },
      () => {
        this.adjustFieldSize(text);
        if (this.inFlightRequest) {
          this.inFlightRequest.abort();
        }

        this.doSearch(text);
        this.setState({ loading: true });
      }
    );
  };

  clearAll = () => {
    if (this.state.selected.length === 0) return;

    this.setState({ selected: [] }, this.selectionDidChange);
    this.trackEvent('Cleared');
  };

  clearSearch = () => {
    if (this.inFlightRequest) {
      this.inFlightRequest.abort();
    }

    this.setState({
      displayValue: '',
      loading: false,
      showingResults: false,
      results: [],
      selectedResultIndex: null
    });

    this.adjustFieldSize('');
  };

  search = (text) => {
    if (typeof this.props.onSearch === 'function') {
      this.props.onSearch(text);
    }

    if (this.state.displayValue.trim() !== text) {
      return;
    }

    this.inFlightRequest = this.searchHandler(this.props.type, text);
    this.inFlightRequest.end((err, response) => {
      if (!err) {
        this.setState({
          results: map(response.body, this.modelizeResult),
          selectedResultIndex: 0,
          showingResults: true,
          loading: false
        });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  searchDelay = (text) => {
    if (this.props.delayShortQuery && text.length < 3) {
      return 500;
    } else {
      return 200;
    }
  };

  doSearch = (text) => {
    const delay_in_ms = this.searchDelay(text);
    debounce(this.search, delay_in_ms)(text);
  };

  modelizeResult = (result) => {
    switch (this.props.type) {
      case 'publishers':
        return new AutocompleteResult.Publisher(result);
      case 'doi_prefixes':
        return new AutocompleteResult.DoiPrefix(result);
      case 'authors':
        return new AutocompleteResult.Author(result);
      case 'departments':
        return new AutocompleteResult.Department(result);
      case 'countries':
        return new AutocompleteResult.Country(result);
      case 'mention_sources':
      case 'mention_sources_types':
        return new AutocompleteResult.MentionSource(result);
      case 'handle_prefixes':
        return new AutocompleteResult.HandlePrefix(result);
      case 'journals':
        return new AutocompleteResult.Journal(result);
      case 'affiliations':
        return new AutocompleteResult.Affiliation(result);
      case 'field_of_research_codes':
        return new AutocompleteResult.FieldOfResearchCode(result);
      case 'funders':
        return new AutocompleteResult.Funder(result);
      case 'grants':
        return new AutocompleteResult.Grant(result);
      case 'sustainable_development_goals':
        return new AutocompleteResult.SustainableDevelopmentGoal(result);
      default:
        throw new Error(`Unrecognised autocomplete result type: ${this.props.type}`);
    }
  };

  selectResult = (match) => {
    this.clearSearch();
    if (typeof this.props.onSelectResult === 'function') {
      this.props.onSelectResult(match);
    }

    // Don't permit duplicates in the list
    const alreadyContainsItem = find(this.state.selected, (i) => i.id === match.id);
    if (!alreadyContainsItem) {
      this.setState({ selected: concat(this.state.selected, match) }, this.selectionDidChange);
      this.trackEvent('Item Selected', match.description);
    }
  };

  overrideSelectedResults = (matches) => {
    this.setState({ selected: matches }, this.selectionDidChange);
  };

  // Other event handlers
  //#############################################################################

  storeInputField = (field) => {
    this.field = field;
  };

  makeFocused = () => {
    if (!this.field || this.state.focused) return;

    this.setState({ focused: true });
    this.field.focus();
  };

  handleMouseDown = () => {
    this.isBeingClicked = true;
  };

  handleClick = () => {
    this.isBeingClicked = false;

    if (!this.fieldSelected) {
      this.fieldSelected = true;
      this.trackEvent('Selected');
    }

    this.makeFocused();
  };

  handleBlur = () => {
    this.setState({ focused: false });

    if (!this.isBeingClicked) {
      this.fieldSelected = false;
    }

    delay(() => {
      if (!this.state.focused) {
        this.clearSearch();
      }
    }, 250);
  };

  didRemoveItem = (item) => {
    this.setState({ selected: filter(this.state.selected, (i) => i.id !== item.id) }, this.selectionDidChange);
    this.trackEvent('Item Removed', item.description);
  };

  adjustFieldSize = (text) => {
    if (!this.field) return;

    let sizer = document.createElement('div');
    sizer.style.position = 'absolute';
    sizer.style.visibility = 'hidden';

    let textNode = document.createTextNode(text);
    sizer.appendChild(textNode);

    this.field.parentElement.appendChild(sizer);
    const width = Math.max(sizer.width, 10) + 25;
    sizer.parentNode.removeChild(sizer);

    this.field.width = width;
  };

  removeLastSelection = () => {
    this.setState({ selected: this.state.selected.slice(0, -1) }, this.selectionDidChange);
  };

  selectionDidChange = () => {
    return typeof this.props.onChangeItems === 'function' ? this.props.onChangeItems(this.state.selected) : undefined;
  };
}

class AutocompleteFieldEmptyMessage extends React.Component {
  static propTypes = {
    queryText: PropTypes.string
  };

  render() {
    return (
      <div className="AutocompleteFieldResults">
        <div className="AutocompleteFieldEmptyMessage">
          {I18n.htmlSafe('autocomplete.no_results', {
            term: this.props.queryText
          })}
        </div>
      </div>
    );
  }
}

export default AutocompleteField;
