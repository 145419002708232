import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Query from 'components/Explorer/models/query';

import QuickSearch from '../QuickSearch';

class Header extends React.Component {
  query = null;

  state = {
    title: null,
    loading: true
  };

  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    onShowAdvancedSearch: PropTypes.func.isRequired,
    logo: PropTypes.string.isRequired,
    instanceName: PropTypes.string.isRequired,
    onSelectShowMore: PropTypes.func
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    this.query.cleanup();
    if (this.inFlightRequest) this.inFlightRequest.abort();
  }

  render() {
    return (
      <header className="Explorer-Header">
        <ReactTooltip id="book-warning" effect="solid" />
        <div className="Explorer-Header-logo">
          <img src={this.props.logo} alt={this.props.instanceName} />
        </div>
        <div className="Explorer-Header-title">
          {window.current_user.books ? null : this.bookWarning}
          {this.state.loading ? this.loadingMessage : this.content}
        </div>
        <div className="Explorer-Header-edit-button">{this.state.loading ? null : this.editButton}</div>
        <div className="Explorer-Header-quicksearch">
          <QuickSearch onSelectResult={this.selectResult} onSelectShowMore={this.selectShowMore} />
        </div>
      </header>
    );
  }

  get loadingMessage() {
    return <span className="loading-message">{I18n.t('Explorer.Header.loading')}</span>;
  }

  get content() {
    return (
      // eslint-disable-next-line -- jsx-a11y [TODO]
      <span
        role="heading"
        className="description"
        aria-level="1"
        onClick={this.openAdvancedSearch}
        dangerouslySetInnerHTML={{ __html: this.titleString }}
      />
    );
  }

  get editButton() {
    return (
      <button className="edit-button" onClick={this.openAdvancedSearch}>
        {I18n.t('Explorer.Header.edit')}
      </button>
    );
  }

  get bookWarning() {
    return (
      <span
        className="Explorer-Header-book-warning"
        data-tooltip-id="book-warning"
        data-tooltip-content={I18n.t('Explorer.Header.book_warning')}
      />
    );
  }

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.title();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        this.setState({ loading: false, title: response.body.title });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  get titleString() {
    return I18n.t('Explorer.Header.title', { title: this.state.title });
  }

  selectResult = (filters) => {
    // mentionSourceOrder sort should be cleaned up, since non-default order only makes sense when filtering by twitter,
    // and all mention_sources filters are removed.
    if (this.query.mentionSourceOrder !== Query.DEFAULT_MENTION_SOURCE_ORDER) {
      this.query.mentionSourceOrder = Query.DEFAULT_MENTION_SOURCE_ORDER;
    }

    this.props.history.push(this.query.withFilters(filters).location);
  };

  selectShowMore = (searchTerm) => {
    if (this.props.onSelectShowMore) this.props.onSelectShowMore(searchTerm);
  };

  openAdvancedSearch = () => {
    this.props.onShowAdvancedSearch();
  };
}

export default withRouter(Header);
