import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import ResearchOutput from 'components/Explorer/models/research_output';
import PageTitle from 'components/Explorer/PageTitle';
import Pagination from 'components/Explorer/Pagination';
import ResearchOutputItem from './ResearchOutputItem';
import ViewSelector from './ViewSelector';
import SortBy from './SortBy';
import Summary from './Summary';
import { Tooltip as ReactTooltip } from 'react-tooltip';

class Outputs extends React.Component {
  query = null;

  state = {
    loading: true,
    updating: false,
    loadingFailed: false,
    data: []
  };

  static propTypes = {
    history: PropTypes.object
  };

  componentDidMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.fetchFreshData, Query.EVENTS.didChangeFilters);
    this.query.registerCallback(this.fetchPageData, Query.EVENTS.didChangePage, Query.EVENTS.didChangeSortOrder);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  render() {
    return (
      <div className="Explorer-Outputs" ref={this.setRoot}>
        <PageTitle metaTitle={I18n.t('Explorer.TabBar.outputs')} />
        <Summary />
        <ReactTooltip id="GrantInfo" effect="solid" />

        <div key="content" className="Explorer-Outputs-Container">
          {this.state.loading ? this.loadingMessage : this.content}
        </div>
      </div>
    );
  }

  get content() {
    if (this.state.loadingFailed) return this.errorMessage;
    if (this.state.data && this.state.data.length === 0) return this.emptyMessage;

    return (
      <div className="Explorer-Outputs-Container-Wrapper">
        {this.header}
        {this.results}
        {this.pagination}
        {this.spinner}
      </div>
    );
  }

  get header() {
    return (
      <div className="Explorer-Outputs-Header">
        <ViewSelector />
        <SortBy />
        <Pagination lastPage={this.state.lastPage} onChangePage={this.trackPageChange} />
      </div>
    );
  }

  get results() {
    const className = this.query.view === 'grid' ? 'Explorer-Outputs-Grid' : 'Explorer-Outputs-List';

    return (
      <div className={className}>
        {this.state.data.map((output) => {
          return <ResearchOutputItem key={`output-${output.id}`} output={output} onBadgeClick={this.onBadgeClick} />;
        })}
      </div>
    );
  }

  get pagination() {
    return <Pagination lastPage={this.state.lastPage} onChangePage={this.trackPageChange} />;
  }

  get spinner() {
    const className = `Explorer-Outputs-Updating-spinner ${this.state.updating ? 'updating' : 'not-updating'}`;

    return <div className={className} />;
  }

  get loadingMessage() {
    return <div className="Explorer-Outputs-Loading-spinner" />;
  }

  get errorMessage() {
    return <div className="error-message">{I18n.t('Explorer.Outputs.error')}</div>;
  }

  get emptyMessage() {
    return <div className="empty-message">{I18n.t('Explorer.Outputs.empty')}</div>;
  }

  onBadgeClick = (output) => {
    Analytics.trackEvent('research-outputs-open-details-page', {
      outputId: output.id
    });

    this.props.history.push(this.query.withShowDetails(output.id).location);
  };

  fetchFreshData = () => {
    this.setState({ loading: true, loadingFailed: false });

    this.fetchData();
  };

  fetchPageData = () => {
    this.setState({ updating: true, loadingFailed: false });

    this.fetchData();
  };

  fetchData = () => {
    this.scrollToTop();

    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.researchOutputs();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let newState = { data: null, lastPage: null };
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        newState = {
          data: response.body.outputs.map((r) => new ResearchOutput(r)),
          lastPage: response.body.lastPage
        };
      }

      this.setState({
        updating: false,
        loading: false,
        loadingFailed,
        ...newState
      });
    });
  };

  setRoot = (elm) => {
    this.root = elm;
  };

  scrollToTop = () => {
    if (!this.root) return;

    const wrapper = this.root.closest('.Explorer-ContentPanel');
    if (wrapper) wrapper.scrollTop = 0;
  };

  trackPageChange = (page) => {
    Analytics.trackEvent('research-outputs-paginate', { page });
  };
}

export default withRouter(Outputs);
