import Ajax from 'util/ajax';

// Make some modules available globally
window.Analytics = require('modules/analytics');
require('util/i18n');

// JS-capable browsers get an attribute on the HTML tag as soon as we run the JS
// - this allows us to handle JS-specific styles in a clean way, with no FOUC
const htmlElement = document.getElementsByTagName('html')[0];
const newHTMLClass = document.documentElement.className.replace('no-js', 'js');
htmlElement.setAttribute('class', newHTMLClass);

require('modules/accessibility').setup();
require('modules/sidebar').setup();
require('modules/settings').setup();
require('modules/admin_panel').setup();
require('modules/sessions').setup();
require('modules/react_helper').setup();
require('modules/tooltips').setup();
require('modules/saved_searches').setup();
require('modules/release_check').setup();

// Loads new JS-based (React) UI
require('./modules/explorer').setup();

window.Analytics.setup();
Ajax.setup();
