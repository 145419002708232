import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';
import { findIndex } from 'lodash-es';

import Query from 'components/Explorer/models/query';
import AwesomeBarSearchManager from 'models/awesome_bar_search_manager';

import SearchLoadingIcon from '../SearchLoadingIcon';
import ResultView from './result_view';
import SearchTerm from 'models/SearchTerm';

import { attachFocusLimiter, detachFocusLimiter } from '../util/focus_limiter';
import { closeAllPopovers } from 'util/popover';

class QuickSearch extends React.Component {
  containerElement = null;
  inputElement = null;
  searchManager = null;

  // The idea of SelectionIndexPath is based on NSIndexPath from the Cocoa
  // libraries - https://developer.apple.com/library/mac/documentation/Cocoa/Reference/Foundation/Classes/NSIndexPath_Class/index.html
  //
  // The idea is to index a node in a tree structure by building an array of
  // indexes which indicate the current position at each level of the tree - e.g.
  // the first item in the first set is `[0, 0]`; the second item in the first
  // set is `[0, 1]`; the fourth item in the third set is `[2, 3]`.
  // It's subsequently pretty easy to determine what item is selected, and to
  // increment or decrement it to navigate the list.

  state = {
    loading: false,
    expanded: false,
    text: '',
    resultSets: [],
    selectionIndexPath: [0, 0],
    scope: 'all'
  };

  static propTypes = {
    history: PropTypes.object,
    onSelectResult: PropTypes.func,
    onSelectShowMore: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.textEntered = false;
  }

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div
        ref={this.containerRef}
        className={`Explorer-QuickSearch ${this.state.expanded ? 'expanded' : 'not-expanded'}`}
      >
        <button
          className="Explorer-QuickSearch-close-button"
          onClick={this.handleClose}
          tabIndex={this.tabIndex}
          onKeyUp={this.onKeyUpButton}
        >
          {I18n.t('Explorer.QuickSearch.close')}
        </button>

        <div className="Explorer-QuickSearch-field-wrapper">
          <SearchLoadingIcon loading={this.state.loading} />
          <input
            ref={this.inputRef}
            className="Explorer-QuickSearch-field"
            placeholder={I18n.t('Explorer.QuickSearch.placeholder')}
            value={this.state.text}
            onFocus={this.handleFocus}
            onChange={(e) => this.updateText(e.target.value)}
            onKeyUp={this.onKeyUp}
            aria-label={I18n.t('Explorer.QuickSearch.placeholder')}
          />
          {window.current_user.institution_integrated && this.institutionalToggle}
        </div>

        {this.state.resultSets.length ? this.results : null}
      </div>
    );
  }

  get results() {
    return (
      <div className="Explorer-QuickSearch-results">
        <ResultView
          resultSets={this.state.resultSets}
          selectedResult={this.selectedResult}
          onSelectResult={this.selectResult}
          loading={this.state.loading}
        />
      </div>
    );
  }

  get institutionalToggle() {
    return (
      <div className="Explorer-Quicksearch-institutional-toggle">
        <button
          onClick={this.setScopeAll}
          className={`full ${this.state.scope == 'all' ? 'active' : 'not-active'}`}
          tabIndex={this.tabIndex}
          onKeyUp={this.onKeyUpButton}
        >
          {I18n.t('Explorer.QuickSearch.institution_toggle.full')}
        </button>

        <button
          onClick={this.setScopeInstitution}
          className={`institution ${this.state.scope == 'institution' ? 'active' : 'not-active'}`}
          tabIndex={this.tabIndex}
          onKeyUp={this.onKeyUpButton}
        >
          {I18n.t('Explorer.QuickSearch.institution_toggle.institution')}
        </button>
      </div>
    );
  }

  // Refs
  //////////////////////////////////////////////////////////////////////////////

  inputRef = (el) => {
    this.inputElement = el;
  };

  containerRef = (el) => {
    this.containerElement = el;
  };

  // Event handlers
  //////////////////////////////////////////////////////////////////////////////

  show = () => {
    closeAllPopovers();

    this.setState(
      {
        expanded: true,
        scope: this.currentScopeFromURL
      },
      attachFocusLimiter(this.containerElement)
    );
  };

  hide = ({ trackClosed = false } = {}) => {
    this.killSearch();

    this.inputElement.focus();

    this.setState(
      {
        expanded: false,
        text: '',
        loading: false
      },
      detachFocusLimiter(this.containerElement)
    );

    if (trackClosed) Analytics.trackEvent('Quick Search: Dialogue Closed');
  };

  handleFocus = () => {
    if (!this.state.expanded) {
      this.show();

      Analytics.trackEvent('Quick Search: Field Selected');
    }
  };

  handleClose = () => {
    this.hide({ trackClosed: true });
  };

  updateText = (text) => {
    if (!this.state.expanded) {
      this.setState({ expanded: true });
    }
    this.killSearch();
    this.trackTextAnalytics(text);
    this.setState({ text });

    if (text.trim() === '') {
      this.setState({ loading: false });
    } else {
      this.startSearch(text);
    }
  };

  trackTextAnalytics = (text) => {
    if (!this.textEntered && text.trim().length > this.state.text.length) {
      this.textEntered = true;
      Analytics.trackEvent('Quick Search: Field Filled');
    }
    if (this.textEntered && text.trim() === '') {
      Analytics.trackEvent('Quick Search: Field Manually Cleared');
      this.textEntered = false;
    }
  };

  onKeyUp = (e) => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        this.hide();
        Analytics.trackEvent('Quick Search:  Dialogue Cancelled');
        break;
      case 'Enter':
        e.preventDefault();
        this.selectResult(this.selectedResult);
        break;
      case 'ArrowUp':
        e.preventDefault();
        this.moveSelectionUp();
        break;
      case 'ArrowDown':
        e.preventDefault();
        this.moveSelectionDown();
        break;
    }
  };

  onKeyUpButton = (e) => {
    if (e.key === 'Escape') {
      this.hide();
      Analytics.trackEvent('Quick Search:  Dialogue Cancelled');
    }
  };

  setScopeAll = (e) => {
    e.preventDefault();
    this.setState({ scope: 'all' });
    Analytics.trackEvent('Quick Search: Scope Changed', { scope: 'full' });
  };

  setScopeInstitution = (e) => {
    e.preventDefault();
    this.setState({ scope: 'institution' });
    Analytics.trackEvent('Quick Search: Scope Changed', {
      scope: 'institution'
    });
  };

  // Actions
  //////////////////////////////////////////////////////////////////////////////

  killSearch = () => {
    if (this.searchManager) this.searchManager.terminate();
    this.setState({
      resultSets: [],
      selectionIndexPath: [0, 0]
    });
  };

  startSearch = (text) => {
    this.searchManager = new AwesomeBarSearchManager(text, {
      updateCallback: this.didUpdateSearch,
      completeCallback: this.didCompleteSearch
    });

    this.setState({ loading: true });
    this.searchManager.startLoading();
  };

  moveSelectionUp = () => {
    const ip = this.state.selectionIndexPath;
    const rs = this.state.resultSets;

    const hasPreviousResultsInSet = ip[1] > 0;
    const hasPreviousSetsInList = ip[0] > 0;

    if (hasPreviousResultsInSet) {
      this.setState({ selectionIndexPath: [ip[0], ip[1] - 1] });
    } else if (hasPreviousSetsInList) {
      const previousResultSet = rs[ip[0] - 1];
      this.setState({
        selectionIndexPath: [ip[0] - 1, previousResultSet.results.length - 1]
      });
    } else {
      const lastResultSet = rs[rs.length - 1];
      this.setState({
        selectionIndexPath: [rs.length - 1, lastResultSet.results.length - 1]
      });
    }
  };

  moveSelectionDown = () => {
    const ip = this.state.selectionIndexPath;
    const rs = this.state.resultSets;

    const hasMoreResultsInSet = rs[ip[0]].results.length > ip[1] + 1;
    const hasMoreSetsInList = rs.length > ip[0] + 1;

    if (hasMoreResultsInSet) {
      this.setState({ selectionIndexPath: [ip[0], ip[1] + 1] });
    } else if (hasMoreSetsInList) {
      this.setState({ selectionIndexPath: [ip[0] + 1, 0] });
    } else {
      this.setState({ selectionIndexPath: [0, 0] });
    }
  };

  selectResult = (result) => {
    this.hide();
    Analytics.trackEvent('Quick Search: Search Applied', {
      value: this.state.text
    });

    const filters = result ? result.filters : {};
    if (window.current_user.institution_integrated) {
      filters.scope = this.state.scope;
    }

    if (result && result.isLinkToMore) {
      if (this.props.onSelectShowMore) {
        const searchTerm = new SearchTerm(result.isLinkToMore, this.state.text);
        this.props.onSelectShowMore(searchTerm);
      }
    } else {
      if (this.props.onSelectResult) this.props.onSelectResult(filters);
    }
  };

  // Search callbacks
  //////////////////////////////////////////////////////////////////////////////

  didUpdateSearch = (resultSets) => {
    let newState = { resultSets };

    // If we have an identifier match, immediately select that. This is because
    // in almost every case, if the user enters an actual identifier they will
    // want to search for it immediately rather than doing a keyword search.
    const identifierIndex = findIndex(resultSets, (r) => r.type == 'identifier');
    if (identifierIndex >= 0) newState.selectionIndexPath = [identifierIndex, 0];

    // As the above, immediately select an ORCiD result if it is available
    const orcidIndex = findIndex(resultSets, (r) => r.type == 'orcid');
    if (orcidIndex >= 0) newState.selectionIndexPath = [orcidIndex, 0];

    this.setState(newState);
  };

  didCompleteSearch = () => {
    this.setState({ loading: false });
  };

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  get selectedResult() {
    const ip = this.state.selectionIndexPath;

    const branch = this.state.resultSets[ip[0]];
    if (!branch) return;

    return branch.results[ip[1]];
  }

  get currentScopeFromURL() {
    return this.query.filters.scope === 'all' ? 'all' : 'institution';
  }

  get tabIndex() {
    return this.state.expanded ? 0 : -1;
  }
}

export default withRouter(QuickSearch);
